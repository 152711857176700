import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil1Icon } from '@radix-ui/react-icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BaseSyntheticEvent, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  addExternalTimeSheetMapping,
  AddExternalTimeSheetMappingProps,
} from '@/api/addExternalTimeSheetMapping.ts';
import {
  editExternalTimeSheetMapping,
  EditExternalTimeSheetMappingProps,
} from '@/api/editExternalTimeSheetMapping.ts';
import { ProjectTaskAndCategoryFormFields } from '@/components/ProjectTaskAndCategoryFormFields.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog.tsx';
import { Form } from '@/components/ui/form.tsx';
import { toast } from '@/components/ui/use-toast.ts';
import { QueryKeys } from '@/lib/utils.ts';
import {
  ExternalTimeSheetMapping,
  ExternalTimeSheetSource,
} from '@/types/ExternalTimeSheetMapping.ts';
import { Project } from '@/types/Project.ts';
import { ExternalTimeSheetEntry } from '@/types/TimeSheetEntry.ts';
import {
  projectTaskCategoryFormSchema,
  ProjectTaskCategoryFormValues,
  setCategoryIsValid,
} from '@/types/TimeSheetEntryFormValues.ts';

import { Icons } from './icons';

type EditExternalTimeEntryButtonProps = {
  externalTimeSheetEntry: ExternalTimeSheetEntry;
  externalTimeSheetSource: ExternalTimeSheetSource;
  onMappingSave: (externalTimeEntryId: string, timeEntry?: ExternalTimeSheetEntry) => void;
  projects: Project[];
  saveTimeEntry: (timeEntry: ExternalTimeSheetEntry) => void;
};

export const EditExternalTimeEntryButton = ({
  externalTimeSheetEntry,
  externalTimeSheetSource,
  onMappingSave,
  projects,
  saveTimeEntry,
}: EditExternalTimeEntryButtonProps) => {
  const saveAndDefaultName = 'save-and-default';

  const queryClient = useQueryClient();
  const [showDialog, setShowDialog] = useState(false);

  const form = useForm<ProjectTaskCategoryFormValues>({
    defaultValues: {
      project: externalTimeSheetEntry.stintId,
      task: externalTimeSheetEntry.taskId,
      category: externalTimeSheetEntry.categoryId,
    },
    mode: 'onChange',
    resolver: zodResolver(projectTaskCategoryFormSchema),
  });

  const { mutate: editMappingMutate, isPending: editMappingPending } = useMutation({
    mutationFn: ({ externalTimeSheetMapping }: EditExternalTimeSheetMappingProps) =>
      editExternalTimeSheetMapping({
        externalTimeSheetMapping,
      }),
    onError: (error) => {
      toast({
        description: error.message,
        title: error.name,
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        description: 'Successfully edited time sheet mapping',
        title: 'Edit Time Sheet Mapping',
      });
      // Refresh the list of time sheet entries
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ExternalTimeSheetMappings] });
      onMappingSave(externalTimeSheetEntry.externalId);
      setShowDialog(false);
    },
  });

  const { mutate: addMappingMutate, isPending: addMappingPending } = useMutation({
    mutationFn: ({ externalTimeSheetMapping }: AddExternalTimeSheetMappingProps) =>
      addExternalTimeSheetMapping({
        externalTimeSheetMapping,
      }),
    onError: (error) => {
      toast({
        description: error.message,
        title: error.name,
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        description: 'Successfully added time sheet mapping',
        title: 'Add Time Sheet Mapping',
      });
      // Refresh the list of time sheet entries
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ExternalTimeSheetMappings] });
      onMappingSave(externalTimeSheetEntry.externalId);
      setShowDialog(false);
    },
  });

  const saveMapping = (data: ProjectTaskCategoryFormValues) => {
    const projectId = projects.find((p) => p.stintId === data.project)?.id ?? '';
    const externalTimeSheetMapping: ExternalTimeSheetMapping = {
      id: externalTimeSheetEntry.mappingId,
      source: externalTimeSheetSource,
      projectId: projectId,
      taskId: data.task ?? '',
      categoryId: data.category,
      externalProject: externalTimeSheetEntry.externalProjectId,
      externalProjectDescription: externalTimeSheetEntry.externalProjectName,
      externalTask: externalTimeSheetEntry.externalTaskId,
      externalTaskDescription: externalTimeSheetEntry.externalTaskName,
    };

    if (externalTimeSheetEntry.mappingId) {
      editMappingMutate({
        externalTimeSheetMapping,
      });
    } else {
      addMappingMutate({
        externalTimeSheetMapping,
      });
    }
  };

  const submitForm = (
    data: ProjectTaskCategoryFormValues,
    event: BaseSyntheticEvent | undefined
  ) => {
    const categoryIsValid = setCategoryIsValid(
      projects,
      data.project,
      data.category,
      form.clearErrors,
      form.setError
    );
    if (!categoryIsValid) {
      return;
    }

    const formEvent = event?.nativeEvent as SubmitEvent;
    const button = formEvent.submitter as HTMLButtonElement;
    const project = projects.find((p) => p.stintId === data.project);
    const task = project?.tasks.find((t) => t.id === data.task);
    const category = project?.categories.find((c) => c.id === data.category);
    const timeEntry: ExternalTimeSheetEntry = {
      ...externalTimeSheetEntry,
      stintId: project?.stintId ?? '',
      projectId: project?.id ?? '',
      taskId: task?.id ?? '',
      categoryId: category?.id,
      usesDefaultProjectOrTask: false,
      projectName: project?.displayName ?? '',
      taskName: task?.displayName ?? '',
      categoryName: category?.displayName,
    };

    if (button.name === saveAndDefaultName) {
      switch (externalTimeSheetSource) {
        case 'Outlook':
          onMappingSave(externalTimeSheetEntry.externalId, timeEntry);
          break;
        case 'Harvest':
          saveMapping(data);
          break;
        default:
          break;
      }
    } else {
      saveTimeEntry(timeEntry);
    }
  };

  return (
    <Dialog open={showDialog} onOpenChange={setShowDialog}>
      <DialogTrigger asChild>
        <Button title="Edit">
          <Pencil1Icon></Pencil1Icon>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Map {externalTimeSheetSource} Entry to Dashboard</DialogTitle>
          <DialogDescription>
            Save for this entry only or set as default for{' '}
            {externalTimeSheetSource === 'Outlook'
              ? 'related Oulook entries.'
              : `this ${externalTimeSheetSource} project/task combo.`}
          </DialogDescription>
        </DialogHeader>
        {externalTimeSheetSource !== 'Outlook' ? (
          <>
            <h2 className="font-semibold">{externalTimeSheetSource}</h2>
            <p className="text-sm">Project: {externalTimeSheetEntry.externalProjectName}</p>
            <p className="text-sm">Task: {externalTimeSheetEntry.externalTaskName}</p>
            <h2 className="font-semibold">Dashboard</h2>
          </>
        ) : (
          ''
        )}

        <Form {...form}>
          <form
            id="external-time-entry-edit"
            onSubmit={form.handleSubmit(submitForm)}
            className="flex w-full flex-col gap-3"
          >
            <ProjectTaskAndCategoryFormFields
              defaultStintId={externalTimeSheetEntry.stintId}
              projects={projects}
              submitPending={addMappingPending || editMappingPending}
              selectSide="top"
            />
          </form>
        </Form>
        <DialogFooter>
          <div className="flex flex-col-reverse gap-3 sm:flex-row sm:justify-between">
            <Button
              form="external-time-entry-edit"
              name="save"
              type="submit"
              disabled={addMappingPending || editMappingPending}
            >
              Save
            </Button>
            <Button
              form="external-time-entry-edit"
              name={saveAndDefaultName}
              type="submit"
              disabled={addMappingPending || editMappingPending}
            >
              {addMappingPending || editMappingPending ? (
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                ''
              )}{' '}
              Save & Default
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
